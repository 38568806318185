


























































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import GroupComponent from '@/mixins/group-component';
import { KnowledgeEntityModel, KnowledgeQueryModel, KnowledgeStatusModel } from '@/entity-model/knowledge-entity';
import KnowledgeService from '@/service/knowledge';
import { ViewModeType } from '@/model/enum';

@Component
export default class KnowledgeListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    KnowledgeEntityModel = KnowledgeEntityModel;
    created() {
        this.initTable({
            service: KnowledgeService,
            queryModel: new KnowledgeQueryModel(),
            tableColumns: KnowledgeEntityModel.getTableColumns()
        });
        this.getList();
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as KnowledgeQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/knowledge?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/knowledge');
        }
    }

    reviewClick(model): void {
        const reviewModel = new KnowledgeStatusModel();
        (this.$refs.formReviewDialog as any).dialogOpen(reviewModel, { update: KnowledgeService.updateReviewStatus }, ViewModeType.UPDATE);
    }
}

